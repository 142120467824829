import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import PublicLayout from "../components/common/Layout/PublicLayout";
import { useAuth } from "../contexts/Auth";

// Public Route prevents access to pages that has the property "protected" set, if the user is authenticated already.
const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const {
    authUser,
    authToken,
    userData,
    isUserBasic,
    canSeeInternal,
    loading,
  } = useAuth();
  const history = useHistory();
  const lastLocation = rest.location.state && rest.location.state.from.pathname;
  useEffect(() => {
    if (authUser && authToken && !loading) {
      if (rest.type === "protected") {
        history.push(
          lastLocation && lastLocation !== "/signout"
            ? lastLocation
            : canSeeInternal
              ? "/home/Intraday/Auto/Up"
              : "/platinum-program/sign-up",
        );
      }
    }
  }, [
    authToken,
    authUser,
    history,
    isUserBasic,
    lastLocation,
    rest.type,
    userData,
    canSeeInternal,
    loading,
  ]);

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.customLayout ? (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        ) : (
          <PublicLayout {...rest}>
            <Component {...props} />
          </PublicLayout>
        )
      }
    />
  );
};

export default PublicRoute;
